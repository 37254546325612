/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './assets/css/main.css';
import Logo from './img/logo.png'

class Home extends React.Component {
    constructor() {
        super();
        this.state = {
            color: false,
            colo2: false,
            get_color2: '',
            name: '',
            gov: '',
            city: '',
            phone: '',
            size: '',
            s40: false,
            s1: false,
            s2: false,
            s3: false,
            s4: false,
        }
    }



    render() {
        return (
            <div className="App">

                <main className="main">
                    <div className="page-header breadcrumb-wrap" style={{ display: 'felx', alignItems: "center", }}>
                        <center>
                              <img width={350} height={150} src={Logo} alt="" />
                            <h3>عيون المستقبل</h3>
                        </center>

                    </div>
                    <div class="single-product-area section-padding-100 clearfix">
            <div class="container-fluid">



                <div class="row">
                    <div class="col-12 col-lg-12" style={{padding:40}}>
                        <div class="single_product_desc">
                            <div class="product-meta-data" style={{textAlign: 'center'}}>
                           
                                <a href="#">
                                    <h3>سياسات الاستبدال او الاسترجاع </h3>
                                </a>
                                
                            </div>

                            <div class="short_overview my-5">
                                <div style={{textAlign: 'right'}}>
                                    <h4 style={{marginBottom:20}}>سياسة استرجاع المنتج</h4>
                                    <p style={{textAlign: 'right', fontSize: 20}}>
                                        لدينا سياسة إرجاع لمدة 7 ايام ، مما يعني أنه لديك 7 ايام بعد استلام المنتج الخاص بك لطلب الإرجاع على ان لا يكون تم استخدام المنتج في نفس الحالة التي استلمته بها ، غير ملبوس أو غير مستخدم ، مع العلامات ، وفي عبوته الأصلية. ستحتاج أيضًا إلى إيصال أو إثبات الشراء. في حال تطابق شروط الاسترجاع يمكنك مراسلتنا عن طريق البريد الاكتروني                                    </p>
                                        <b>refund@future-eyes.store</b>
                                    </div>
                                    <div class="short_overview my-5">
                                        <div style={{textAlign: 'right'}}>
                                            <h4 style={{marginBottom:20}}>سياسة استبدال المنتج</h4>
                                            <p style={{textAlign: 'right', fontSize: 20}}>
                                               . لدينا سياسة استبدال المنتج في حال كان الخطا في التجهيز الطلب ويمكنك طلب استبدال كحد اقصى هو 24 ساعة من استلام المنتج وعلى ان يكون غير ملبوس أو غير مستخدم ، مع العلامات ، وفي عبوته الأصلية. ستحتاج أيضًا إلى إيصال أو إثبات الشراء. في حال تطابق شروط الاسترجاع يمكنك مراسلتنا عن طريق البريد الاكتروني
                                            </p>
                                               <b>refund@future-eyes.store</b>
                                            </div>
                                           </div>

                         
                        </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
                </main>
            </div>
        );
    }
}

export default Home;
